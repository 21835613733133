.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
@import url(https://fonts.googleapis.com/css?family=Caveat);
@import url(https://fonts.googleapis.com/css?family=MedievalSharp);

/* Base */
body {
  padding: 0;
  margin: 0 !important;
  background-color: #c4c4c4;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  margin-top: 0;
  z-index: 0;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 100%);
  }
  to {
    border-right-color: transparent;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#tsparticles {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
}

#root {
  height: 100%;
  width: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}
